import { COLORS } from "@constants/common"
import { Button, CircularProgress } from "@mui/material"

function PrimaryButton({
  handleSubmit,
  label,
  className,
  isLoading,
  disabled,
}: {
  handleSubmit: () => void
  label: string
  className: string
  isLoading?: boolean
  disabled?: boolean
}) {
  return (
    <Button
      disabled={disabled || isLoading}
      className={className}
      color="primary"
      onClick={handleSubmit}
      sx={{
        background: COLORS.PRIMARY_COLOR,
        fontSize: "16px",
        color: "#fff",
        fontWeight: "600",
        width: "100%",
        height: "50px",
        "&:hover": {
          backgroundColor: COLORS.PRIMARY_COLOR,
        },
        "&.Mui-disabled": {
          color: "#fff",
          opacity: "0.5",
        },
      }}
    >
      {isLoading ? <CircularProgress color="inherit" size="1.5rem" /> : label}
    </Button>
  )
}

export default PrimaryButton
