import { createAppSlice } from "@redux/createAppSlice"

export interface UserSessionSliceState {
  isLoggedIn: boolean
}

const initialState: UserSessionSliceState = {
  isLoggedIn: false,
}

export const userSessionSlice = createAppSlice({
  name: "userSessionSlice",
  initialState,
  reducers: create => ({
    userLoggedIn: create.reducer(state => {
      state.isLoggedIn = true
    }),
    userLoggedOut: create.reducer(state => {
      state.isLoggedIn = false
    }),
  }),
  selectors: {
    selectIsLoggedIn: appData => appData.isLoggedIn,
  },
})

export const { userLoggedIn, userLoggedOut } = userSessionSlice.actions
export const { selectIsLoggedIn } = userSessionSlice.selectors
