import { useAppSelector } from "@redux/hooks"
import { selectIsLoggedIn } from "@features/common/commonSlices/UserSessionDetailSlice"
import PAGE_URLS from "constants/pageUrls"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useNavigate } from "react-router-dom"

const PrivateRoute = () => {
  const navigate = useNavigate()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const {
    i18n: { language },
  } = useTranslation()
  useEffect(() => {
    if (!isLoggedIn)
      navigate(`/${PAGE_URLS.BASE_PATH}/${language}/${PAGE_URLS.LOGIN}`)
  }, [navigate, isLoggedIn])

  return <>{isLoggedIn && <Outlet />}</>
}

export default PrivateRoute
