// import type { PayloadAction } from "@reduxjs/toolkit"
import { type PayloadAction } from "@reduxjs/toolkit"
import { createAppSlice } from "../../../redux/createAppSlice"
// import type { AppThunk } from './store';
// import { fetchCount } from './counterAPI';

export interface AppDataSliceState {
  notifier: {
    show: boolean
    message: string
    severity?: "error" | "success" | "info" | "warning" | undefined
  }
}

const initialState: AppDataSliceState = {
  notifier: {
    show: false,
    message: "",
    severity: "success",
  },
}

// If you are not using async thunks you can use the standalone `createSlice`.
export const appDataSlice = createAppSlice({
  name: "appData",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: create => ({
    notifyUser: create.reducer(
      (
        state,
        action: PayloadAction<{
          message: string
          severity?: "error" | "success" | "info" | "warning" | undefined
        }>,
      ) => {
        state.notifier.message = action.payload.message
        state.notifier.severity = action.payload.severity
        state.notifier.show = true
      },
    ),
    closeNotifier: create.reducer(state => {
      state.notifier.message = ""
      state.notifier.severity = "success"
      state.notifier.show = false
    }),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    selectNotifier: appData => appData.notifier,
  },
})

// Action creators are generated for each case reducer function.
export const { notifyUser, closeNotifier } = appDataSlice.actions

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const { selectNotifier } = appDataSlice.selectors

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());

//     if (currentValue % 2 === 1 || currentValue % 2 === -1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };
