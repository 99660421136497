import API_ENDPOINTS, { API_SLUG } from "@constants/apiUrls"
import { transformErrorResponse } from "@services/apiError"
import { ApiInit } from "@services/customBaseQuery"
import type { IMyCampaign } from "./types/myCampaignApiType"

const myCampaignApi = ApiInit.injectEndpoints({
  endpoints: build => ({
    myCampaignList: build.mutation<IMyCampaign, { lng: string; url?: string }>({
      query: ({ lng, url }: { lng: string; url?: string }) => ({
        method: "GET",

        url: url || lng + "/" + API_SLUG + API_ENDPOINTS.MY_CAMPAIGNS,
        credentials: "include", // BE will set a variable called `otp_sessionid` in cookie
      }),
      transformErrorResponse: transformErrorResponse,
    }),
    campaignDelete: build.mutation<{}, { url: string }>({
      query: ({ url }: { url: string }) => ({
        method: "DELETE",
        url,
        credentials: "include", // BE will set a variable called `otp_sessionid` in cookie
      }),
      transformErrorResponse: transformErrorResponse,
    }),
  }),
})

export const { useMyCampaignListMutation, useCampaignDeleteMutation } =
  myCampaignApi
