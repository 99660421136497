import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query"
import { Mutex } from "async-mutex"
import { type RootState } from "@redux/store"
import API_ENDPOINTS, { API_BASE_URL, API_SLUG } from "@constants/apiUrls"
import {
  type TokenRefreshResponseDataInterface,
  type OAuthTokenResponseDataInterface,
} from "@interfaces/apidata.interface"
import {
  setAccessToken,
  setErrorStatus,
  setOAuthToken,
} from "../features/userAuth/UserAuthSlice"
import { notifyUser } from "@features/common/commonSlices/AppDetailSlice"
import cookie from "cookie"
import { captureException } from "@sentry/react"
import { checkTokenExpired } from "@utils/checkTokenExpired"
// import API_ENDPOINTS, { API_BASE_URL } from "@constants/apiUrls"

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  //   https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery#prepareheaders
  prepareHeaders: (headers, { getState }) => {
    const oAuthToken = (getState() as RootState).authSlice.oAuthToken
    const accessToken = (getState() as RootState).authSlice.accessToken
    const isLoggedIn = (getState() as RootState).userSessionSlice.isLoggedIn
    headers.set("Is-Yc-Request", "true")
    if (oAuthToken && !isLoggedIn) {
      // if user is not logged in we will add authToken with each request
      headers.set("Content-Type", "application/json")
      headers.set("Authorization", `Bearer ${oAuthToken}`)
    }
    if (accessToken && isLoggedIn) {
      // if user is not logged in we will add authToken with each request
      headers.set("Authorization", `Bearer ${accessToken}`)
    }
    return headers
  },
})

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors

// create a new mutex
const mutex = new Mutex()
const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // isLoggedIn Variable
  const isLoggedIn = (api.getState() as RootState).userSessionSlice.isLoggedIn
  if (isLoggedIn) {
    // here we will handle accessToken Refresh
    // we will intercept the request before sending it and check if the accessToken is valid
    // if not we will update the accessToken
    const accessToken = (api.getState() as RootState).authSlice.accessToken
    const { yc_access_token_expiry } = cookie.parse(document.cookie)
    const isTokenExpired = checkTokenExpired(yc_access_token_expiry)
    if (isTokenExpired || !accessToken) {
      // access token expired or page refreshed
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          const refreshResult = await baseQuery(
            {
              url: API_SLUG + API_ENDPOINTS.TOKEN_REFRESH,
              method: "POST",
              credentials: "include",
            },
            api,
            extraOptions,
          )
          if (refreshResult.data && typeof refreshResult.data === "object") {
            const { data } =
              refreshResult.data as TokenRefreshResponseDataInterface
            api.dispatch(setAccessToken(data.access))
          } else {
            // what can we do if the refresh endpoint fail?
          }
        } catch (err) {
          captureException(err, {
            tags: { location: "refresh token" },
          })
        } finally {
          // release must be called once the mutex should be released again.
          release()
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock()
      }
    }
  }
  let result: any
  try {
    result = await baseQuery(args, api, extraOptions)
  } catch (err) {
    captureException(err, {
      tags: { location: "extra" },
    })
  }
  // handle oAuthToken Refresh
  // if the result is 401 which means oAuthToken Expired we will update oAuthToken and retry the original request
  if (!isLoggedIn && result.error && result.error.status === 401) {
    const bodyData = new FormData()
    bodyData.append("grant_type", import.meta.env.VITE_GRANT_TYPE)
    bodyData.append("client_id", import.meta.env.VITE_CLIENT_ID)
    bodyData.append("client_secret", import.meta.env.VITE_CLIENT_SECRET)
    let refreshResult: any
    try {
      refreshResult = await baseQuery(
        {
          url: API_SLUG + API_ENDPOINTS.GET_O_AUTH_TOKEN,
          method: "POST",
          body: bodyData,
        },
        api,
        extraOptions,
      )
    } catch (err) {
      captureException(err, {
        tags: { location: "Get OAuth Token" },
        extra: { bodyData },
      })
    }
    if (refreshResult.data && typeof refreshResult.data === "object") {
      const { access_token } =
        refreshResult.data as OAuthTokenResponseDataInterface
      api.dispatch(setOAuthToken(access_token))

      // retry the initial query
      result = await baseQuery(args, api, extraOptions)
    } else {
      // what can we do if the token endpoint fail?
    }
  }

  if (result.error) {
    // handle common errors
    if (result.error.status === 429) {
      api.dispatch(
        notifyUser({
          message: (result.error as any).data.message,
          severity: "error",
        }),
      )
    } else {
      api.dispatch(setErrorStatus(result.error.status))
    }
  }

  return result
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const ApiInit = createApi({
  reducerPath: "fetcherApi",
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
})
