export const DOWNLOAD_APP_DATA = {
  data: {
    storeDetails: [
      {
        id: 0,
        itemImage: "/corporate/assets/app-store.png",
        itemName: "Apple badge",
        itemUrl:
          "https://geo.itunes.apple.com/us/app/you-got-a-gift/id1003784085?mt=8",
      },
      {
        id: 1,
        itemImage: "/corporate/assets/play-store.png",
        itemName: "Android badge",
        itemUrl:
          "https://play.google.com/store/apps/details?id=com.yougotagift.YouGotaGiftApp",
      },
      {
        id: 2,
        itemImage: "/corporate/assets/app-gallery.png",
        itemName: "Huawei badge",
        itemUrl: "https://appgallery.huawei.com/app/C100109349",
      },
    ],
    bannerImage: "/corporate/assets/app-download-image.png",
    bannerImageAr: "/corporate/assets/app-download-image-ar.png",
    qrCode:
      "https://cdn.stores.ecom.yougotagift.com/static/site_config/app-download-production.png",
  },
}

export const HELPLINE_DATA = {
  data: {
    email: "help@yougotagift.com",
    helpDesk: [
      {
        phone: "+971 4 441 8473",
        timing: "Sunday - Saturday 9AM - 10PM UAE",
        country: {
          name: "UAE",
          flagImage: "/corporate/assets/uae-flag.png",
        },
      },
    ],
  },
}

export const FAQ_DATA = {
  data: {
    en: `
    <ul>
      <li>How can I change my registered email address?</li>
    </ul>
    <p class="ql-indent-1">Please reach out to the customer service team of the organization from whom you have received this eGift Card.</p>
    <ul>
      <li>Is there an option to login as a guest user?</li>
    </ul>
    <p class="ql-indent-1">You must always register with your Name, Email ID, and Phone number, in order to verify and receive your eGift Card.</p>
    <ul>
      <li>Can I directly use my eGift Card number at any of the stores from the listed brands?</li>
    </ul>
    <p class="ql-indent-1">No. It is mandatory to register the eGift Card Number in the Gift Shop link provided to you.</p>
    <ul>
      <li>How can I use my Shopping Balance?</li>
    </ul>
    <p class="ql-indent-1">You can order the eGift Card of your choice from the listed brands based on its available denominations.</p>
    <ul>
      <li>Can I use my Shopping Balance partially?</li>
    </ul>
    <p class="ql-indent-1">Yes, your available Shopping Balance can be partially split and used for a brand. The remaining balance can be further used for any other brand.</p>
    <ul>
      <li>Can I redeem my eGift Card of the selected brand both Online and In-store?</li>
    </ul>
    <p class="ql-indent-1">For the redemption details, please check the Terms and Conditions of the selected brand.</p>
    <ul>
      <li>Can I avail the eGift Card Number for any region?</li>
    </ul>
    <p class="ql-indent-1">The eGift Card number provided to you is region specific. e.g: If you have received an eGift Card number in AED, it can only be used for the available brands within the UAE region.</p>
    <ul>
      <li>Once I have placed an order can I reverse the action?</li>
    </ul>
    <p class="ql-indent-1">Once the order is processed, the action is irreversible. Cancellations, extensions, and refunds are not applicable.</p>
    <ul>
      <li>Can I extend the validity of my eGift Card?</li>
    </ul>
    <p class="ql-indent-1">Unfortunately, the eGift Card validity cannot be extended.</p>
    <ul>
      <li>How do I redeem the eGift Card at the store?</li>
    </ul>
    <p class="ql-indent-1">Kindly check the brand’s Terms and Conditions for the redemption details.</p>
    <ul>
      <li>How do I contact <a href="http://YouGotaGift.com" rel="noopener noreferrer" target="_blank">YOUGotaGift?</a></li>
    </ul>
    <p class="ql-indent-1">For any queries, please reach out to our Customer Support Team via <a href="mailto:help@yougotagift.com" rel="noopener noreferrer" target="_blank">help@yougotagift.com</a>; Helpline- UAE: <a href="tel:+97144418473" rel="noopener noreferrer" target="_blank">+97144418473</a>, KSA: <a href="tel:+966920000175" rel="noopener noreferrer" target="_blank">+966920000175</a>. (9 am to 10 pm UAE time/Everyday).</p>`,
    ar: `
    <ul class="ql-align-right ql-direction-rtl">
      <li class="ql-align-right ql-direction-rtl">كيف يمكنني تغيير عنوان البريد الإلكتروني الخاصّ بي المسجّل؟</li>
    </ul>
    <p class="ql-indent-1 ql-align-right ql-direction-rtl">يرجى التواصل مع فريق خدمة العملاء التابع للشركة التي تلقيت منها بطاقة الهدايا الإلكترونية هذه.</p>
    <ul class="ql-align-right ql-direction-rtl">
      <li class="ql-align-right ql-direction-rtl">هل من خيار لتسجيل الدخول كمستخدم ضيف؟</li>
    </ul>
    <p class="ql-indent-1 ql-align-right ql-direction-rtl">يجب دائماً تسجيل الدخول عبر إدخال الاسم، وعنوان البريد الإلكتروني ورقم الهاتف من أجل التحقّق من بطاقة الهدايا الإلكترونية الخاصة بك واستلامها.</p>
    <ul class="ql-align-right ql-direction-rtl">
      <li class="ql-align-right ql-direction-rtl">هل يمكنني استخدام رقم بطاقة الهدايا الإلكترونية الخاصة بي على الفور في أي من المتاجر التابعة للعلامات التجارية المدرجة؟</li>
    </ul>
    <p class="ql-indent-1 ql-align-right ql-direction-rtl">كلا، يجب تسجيل رقم بطاقة الهدايا الإلكترونية في رابط متجر الهدايا المعطى لك.</p>
    <ul class="ql-align-right ql-direction-rtl">
      <li class="ql-align-right ql-direction-rtl">كيف يمكنني استخدام رصيد التسوّق الخاصّ بي؟</li>
    </ul>
    <p class="ql-indent-1 ql-align-right ql-direction-rtl">يمكنك طلب بطاقة الهدايا الإلكترونية التي تختارها من العلامات التجارية المدرجة بحسب فئاتها المتاحة.</p>
    <ul class="ql-align-right ql-direction-rtl">
      <li class="ql-align-right ql-direction-rtl">هل يمكنني استخدام رصيد التسوّق الخاصّ بي جزئياً؟</li>
    </ul>
    <p class="ql-indent-1 ql-align-right ql-direction-rtl">نعم، يمكن تقسيم رصيد التسوّق الخاص بك واستخدامه بشكل جزئي في متاجر العلامة التجارية التي تختارها. ويمكن استخدام الرصيد المتبقي في أي علامة تجارية أخرى.</p>
    <ul class="ql-align-right ql-direction-rtl">
      <li class="ql-align-right ql-direction-rtl">هل يمكنني استبدال بطاقة الهدايا الإلكترونية الخاصة بي في العلامة التجارية المحددة عبر الإنترنت وفي المتاجر؟</li>
    </ul>
    <p class="ql-indent-1 ql-align-right ql-direction-rtl">للحصول على تفاصيل الاستبدال، يرجى مراجعة شروط وأحكام العلامة التجارية المحددة.</p>
    <ul class="ql-align-right ql-direction-rtl">
      <li class="ql-align-right ql-direction-rtl">هل يمكنني الاستفادة من رقم بطاقة الهدايا الإلكترونية لأي منطقة؟</li>
    </ul>
    <p class="ql-indent-1 ql-align-right ql-direction-rtl">رقم بطاقة الهدايا الإلكترونية المعطى لك مخصص لمنطقة معينة. على سبيل المثال: إذا تلقيت رقم بطاقة هدايا إلكترونية بالدرهم الإماراتي، فلا يمكن استخدامه إلا في العلامات التجارية المتاحة في الإمارات العربية المتحدة.</p>
    <ul class="ql-align-right ql-direction-rtl">
      <li class="ql-align-right ql-direction-rtl">عند تقديم طلب، هل يمكنني توقيف هذا الإجراء؟</li>
    </ul>
    <p class="ql-indent-1 ql-align-right ql-direction-rtl">بمجرد معالجة طلب ما، لا يمكن توقيف الإجراء. لا تنطبق الإلغاءات، والتمديدات والمبالغ المستردة.</p>
    <ul class="ql-align-right ql-direction-rtl">
      <li class="ql-align-right ql-direction-rtl">هل يمكنني تمديد صلاحية بطاقة الهدايا الإلكترونية الخاصة بي؟</li>
    </ul>
    <p class="ql-indent-1 ql-align-right ql-direction-rtl">للأسف، لا يمكن تمديد صلاحية بطاقة الهدايا الإلكترونية.</p>
    <ul class="ql-align-right ql-direction-rtl">
      <li class="ql-align-right ql-direction-rtl">كيف يمكنني استبدال بطاقة الهدايا الإلكترونية في المتجر؟</li>
    </ul>
    <p class="ql-indent-1 ql-align-right ql-direction-rtl">يرجى التحقق من شروط وأحكام العلامة التجارية للحصول على تفاصيل الاستبدال.</p>
    <ul class="ql-align-right ql-direction-rtl">
      <li class="ql-align-right ql-direction-rtl">كيف أتواصل مع <a href="http://YouGotaGift.com" rel="noopener noreferrer" target="_blank" class="ql-align-right ql-direction-rtl">YouGotaGift?</a></li>
    </ul>
    <p class="ql-indent-1 ql-align-right ql-direction-rtl">لأي استفسار، يرجى التواصل مع فريق دعم العملاء الخاصّ بنا عبر البريد الإلكتروني التاليhelp@yougotagift.com ؛ أو عبر الاتصال على خطّ المساعدة في الإمارات العربية المتحدة:8473 441 4 971+، أو في المملكة العربية السعودية: 175 00 00 92 966+. (من الساعة الـ 9 صباحاً إلى الـ 10 مساءً بتوقيت الإمارات العربية المتحدة/ كلّ يوم).</p>
`,
  },
}

export const STATIC_BLOCK_DATA = {
  data: {
    en: `
    <p><strong>How to redeem your free gift voucher</strong></p>
    <p><br></p>
    <ol>
      <li>Enter your code, sent to you by SMS/Mail, your email address and your phone number and click submit.</li>
      <li>Choose a retailer of your choice. You can avail the amount present in your eGift Card balance.</li>
      <li>Select an amount to spend at store and click proceed.</li>
      <li>The voucher will be sent to the registered email address.</li>
      <li>Please follow voucher instructions for redemption.</li>
    </ol>`,
    ar: `
    <p><strong>كيفية استبدال قسيمة الهدية المجانية الخاصة بك</strong></p>
    <p><br></p>
    <ol>
      <li>أدخل الرمز الذي تلقيته عبر رسالة نصية/بريد إلكتروني وعنوان بريدك الإلكتروني ورقم هاتفك ثم انقر على زر "إرسال</li>
      <li>اختر تاجرًا من اختيارك. يمكنك الاستفادة من المبلغ المتواجد في رصيد بطاقتك الإلكترونية كهدية.</li>
      <li>اختر مبلغاً للإنفاق في المتجر وانقر على زر المتابعة</li>
      <li>سيتم إرسال القسيمة إلى عنوان البريد الإلكتروني المُسجل </li>
      <li>يرجى اتباع تعليمات القسيمة للاستبدال.</li>
    </ol>`,
  },
}

export const DEFAULT_CREATE_CAMPAIGN_DATA = {
  data: {
    title: {
      en: `Congratulations!`,
      ar: `مبروك!`,
    },
    redemption_type: "both",
    validation_type: "email_and_phone",
    defaultBannerEnImageUrl:
      "/corporate/assets/gift-shop/top-banner-1600x180.png",
    defaultBannerArImageUrl:
      "/corporate/assets/gift-shop/top-banner-1600x180.png",
    defaultStaticBlockEnImageUrl:
      "/corporate/assets/gift-shop/inside-banner-640x181.png",
    defaultStaticBlockArImageUrl:
      "/corporate/assets/gift-shop/inside-banner-640x181.png",
  },
}
