import { createAppSlice } from "@redux/createAppSlice"
import { type PayloadAction } from "@reduxjs/toolkit"
export interface UserDetails {
  corporate_name: string
  email: string
  username: string
  corporate_slug: string
  corporate_logo: string
  allowed_contents: string[]
}
export interface AccountBalance {
  corporate_balance: string
  corporate_currency: string
  free_balance: string
  allocated_campaign_balance: string
}
export interface CampaignCount {
  total_campaigns: number | null | undefined
  active_campaigns: number | null | undefined
}
export interface ProfileDataSliceState {
  profileData: UserDetails
  accountBalance: AccountBalance
  campaignCount: CampaignCount
  basicDetails: BasicDetails
}
interface Denominations {
  is_custom_amount_allowed: boolean
  denominations: number[]
  denomination_ranges: { min: number; max: number }
  decimal_notations: number
}
interface Brand {
  id: number
  name: string
  product_image_url: string
  denominations: Denominations
}

export interface BasicDetails {
  currencyCode: string
  countryCode: string
  timezone: string
  genericBrands: Brand[]
}
const initialState: ProfileDataSliceState = {
  profileData: {
    corporate_name: "",
    email: "",
    username: "",
    corporate_slug: "",
    corporate_logo: "",
    allowed_contents: [],
  },
  accountBalance: {
    corporate_balance: "",
    corporate_currency: "",
    free_balance: "",
    allocated_campaign_balance: "",
  },
  campaignCount: {
    total_campaigns: null,
    active_campaigns: null,
  },
  basicDetails: {
    currencyCode: "",
    countryCode: "",
    timezone: "",
    genericBrands: [],
  },
}

export const profileDataSlice = createAppSlice({
  name: "profileDataSlice",
  initialState,
  reducers: create => ({
    updateProfileData: create.reducer(
      (state, action: PayloadAction<UserDetails>) => {
        state.profileData = action.payload
      },
    ),
    updateAccountBalance: create.reducer(
      (state, action: PayloadAction<AccountBalance>) => {
        state.accountBalance = action.payload
      },
    ),
    updateCampaignCount: create.reducer(
      (state, action: PayloadAction<CampaignCount>) => {
        state.campaignCount = action.payload
      },
    ),
    updateBasicDetails: create.reducer(
      (state, action: PayloadAction<BasicDetails>) => {
        state.basicDetails = action.payload
      },
    ),
  }),
  selectors: {
    selectProfileData: appData => appData.profileData,
    selectAccountBalance: appData => appData.accountBalance,
    selectCampaignCount: appData => appData.campaignCount,
    selectBasicDetails: appData => appData.basicDetails,
  },
})

export const {
  updateProfileData,
  updateAccountBalance,
  updateCampaignCount,
  updateBasicDetails,
} = profileDataSlice.actions
export const {
  selectProfileData,
  selectAccountBalance,
  selectCampaignCount,
  selectBasicDetails,
} = profileDataSlice.selectors
