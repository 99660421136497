import { useLayoutEffect } from "react"
import { useTranslation } from "react-i18next"
import { Helmet, HelmetProvider } from "react-helmet-async"
import i18n from "../i18n"
import PageLoader from "@features/common/pageLoader/PageLoader"

interface TranslationProviderProps {
  lang?: string
  children: JSX.Element
}

const TranslationProvider = ({ lang, children }: TranslationProviderProps) => {
  const { ready } = useTranslation()
  const currentLang = i18n.language

  useLayoutEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  if (!ready) {
    return <PageLoader />
  }
  return (
    <HelmetProvider>
      <Helmet>
        <link
          id="favicon"
          rel="icon"
          href="/corporate/assets/favicon.png"
          type="image/x-icon"
        />
        <title>YouGotaGift.com | YOU Campaign</title>
        <html
          lang={currentLang}
          dir={currentLang === "ar" ? "rtl" : "ltr"}
        ></html>
      </Helmet>
      {children}
    </HelmetProvider>
  )
}

export default TranslationProvider
