export const checkTokenExpired = (tokenExpiry: string | undefined) => {
  if (tokenExpiry) {
    // Get the current UTC time
    const currentTime = new Date()

    // added a buffer 3 min   to avoid edge case failure
    const currentTimePlusThreeMinutes = new Date(
      currentTime.getTime() + 3 * 60 * 1000,
    )

    const expiryDate = new Date(tokenExpiry)
    if (isNaN(expiryDate.getTime())) {
      return true
    } else {
      // Check if the access token expiry time is in the future
      const hasExpired = expiryDate < currentTimePlusThreeMinutes
      return hasExpired
    }
  } else {
    return true
  }
}
