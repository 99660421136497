import i18n from "i18next"
import Backend from "i18next-http-backend"
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next"
import enTranslation from "../public/locales/en/translation.json"
import arTranslation from "../public/locales/ar/translation.json"
i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    resources: {
      en: { translation: enTranslation },
      ar: { translation: arTranslation },
    },
    fallbackLng: ["en", "ar"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      useSuspense: false,
    },
  })

export default i18n
