import { useEffect } from "react"
import { useLogoutMutation } from "@features/home/HomeApi"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import PAGE_URLS from "@constants/pageUrls"

const useSyncLogout = () => {
  const navigate = useNavigate()

  const {
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === "isLoggedIn") {
        navigate(`/${PAGE_URLS.BASE_PATH}/${language}/${PAGE_URLS.LOGIN}`)
        window.location.reload()
      }
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])
}

export default useSyncLogout
