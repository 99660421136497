import CtaModal from "@features/common/confirmModal/ConfirmModal"
import Modal from "@features/common/confirmModal/ConfirmModal"
import { useTranslation } from "react-i18next"

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  submitFunction: () => void
  isLoading: boolean
}

const CampaignDeleteModal = ({
  open,
  setOpen,
  submitFunction,
  isLoading,
}: Props) => {
  const { t } = useTranslation()
  return (
    <div>
      <CtaModal
        submitFunction={submitFunction}
        submitText={t("yesDelete")}
        title={t("deleteCampaign")}
        open={open}
        handleClose={() => setOpen(false)}
        isLoading={isLoading}
        cancelText={t("noGoBack")}
        handleCancel={() => setOpen(false)}
      >
        <p className="fs16 cl-pale-sky">{t("campaignDelete")}</p>
      </CtaModal>
    </div>
  )
}

export default CampaignDeleteModal
