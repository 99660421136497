import { type PayloadAction } from "@reduxjs/toolkit"
import { createAppSlice } from "@redux/createAppSlice"

export interface AuthSliceState {
  oAuthToken: string
  accessToken: string
  errorStatus: any
}

const initialState: AuthSliceState = {
  oAuthToken: "",
  accessToken: "",
  errorStatus: undefined,
}

export const authSlice = createAppSlice({
  name: "authSlice",
  initialState,
  reducers: create => ({
    setOAuthToken: create.reducer((state, action: PayloadAction<string>) => {
      state.oAuthToken = action.payload
    }),
    setAccessToken: create.reducer((state, action: PayloadAction<string>) => {
      state.accessToken = action.payload
    }),
    setErrorStatus: create.reducer(
      (
        state,
        action: PayloadAction<
          | number
          | "FETCH_ERROR"
          | "PARSING_ERROR"
          | "TIMEOUT_ERROR"
          | "CUSTOM_ERROR"
        >,
      ) => {
        state.errorStatus = action.payload
      },
    ),
    clearErrorStatus: create.reducer(state => {
      state.errorStatus = undefined
    }),
  }),
  selectors: {
    selectOAuthToken: appData => appData.oAuthToken,
    selectErrorStatus: appData => appData.errorStatus,
    selectAccessToken: appData => appData.accessToken,
  },
})

export const {
  setOAuthToken,
  setAccessToken,
  setErrorStatus,
  clearErrorStatus,
} = authSlice.actions
export const { selectOAuthToken, selectErrorStatus, selectAccessToken } =
  authSlice.selectors
