import { COLORS } from "@constants/common"

export const HomeIcon = ({ fill }: { fill: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 18.984V10.35a1.028 1.028 0 0 0-.4-.812l-7-5.334a.99.99 0 0 0-1.2 0l-7 5.334a1.012 1.012 0 0 0-.4.812v8.635c0 .27.105.528.293.718.187.191.442.298.707.298h4c.265 0 .52-.107.707-.297.188-.191.293-.45.293-.719v-3.048c0-.269.105-.527.293-.718a.992.992 0 0 1 .707-.297h2c.265 0 .52.107.707.297.188.19.293.45.293.719v3.047c0 .27.105.528.293.718.187.191.442.298.707.298h4c.265 0 .52-.107.707-.297.188-.191.293-.45.293-.719z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const TimerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9.089 17a7.967 7.967 0 0 1-3.155-.626 8.14 8.14 0 0 1-2.568-1.714 8.117 8.117 0 0 1-1.733-2.54A7.735 7.735 0 0 1 1 9c0-1.11.211-2.15.633-3.12.422-.97 1-1.817 1.733-2.54a8.264 8.264 0 0 1 2.568-1.714A7.866 7.866 0 0 1 9.09 1a7.97 7.97 0 0 1 3.318.712 8.383 8.383 0 0 1 2.748 1.984V1.808a.49.49 0 0 1 .507-.5c.143 0 .263.048.36.143a.48.48 0 0 1 .144.357v2.846c0 .229-.078.42-.235.575a.798.798 0 0 1-.581.233h-2.878a.491.491 0 0 1-.505-.501.496.496 0 0 1 .506-.5h2.001a7.846 7.846 0 0 0-2.437-1.805A6.778 6.778 0 0 0 9.09 2c-1.972 0-3.645.68-5.018 2.038C2.698 5.397 2.01 7.051 2.01 9c-.002 1.95.685 3.604 2.06 4.963 1.374 1.36 3.047 2.038 5.017 2.037 1.666 0 3.14-.517 4.424-1.55 1.282-1.033 2.107-2.345 2.474-3.934a.654.654 0 0 1 .23-.35.489.489 0 0 1 .38-.093c.153.02.265.093.336.218a.512.512 0 0 1 .045.409c-.385 1.843-1.31 3.353-2.777 4.532C12.734 16.411 11.031 17 9.09 17zm.505-8.208 3.033 3a.502.502 0 0 1 .152.344.463.463 0 0 1-.152.364.5.5 0 0 1-.358.16.496.496 0 0 1-.357-.16L8.828 9.45a.794.794 0 0 1-.189-.274.805.805 0 0 1-.057-.297V4.5A.493.493 0 0 1 9.09 4a.49.49 0 0 1 .505.5v4.292z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth=".5"
      />
    </svg>
  )
}

export const EditIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M0 12.9231H15.2727V14H0V12.9231ZM12.7636 3.76923C13.2 3.33846 13.2 2.69231 12.7636 2.26154L10.8 0.323077C10.3636 -0.107692 9.70909 -0.107692 9.27273 0.323077L1.09091 8.4V11.8462H4.58182L12.7636 3.76923ZM10.0364 1.07692L12 3.01538L10.3636 4.63077L8.4 2.69231L10.0364 1.07692ZM2.18182 10.7692V8.83077L7.63636 3.44615L9.6 5.38462L4.14545 10.7692H2.18182Z"
        fill={fill}
      />
    </svg>
  )
}
export const SubtractIcon = ({ fill }: { fill?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
  >
    <path
      d="M13.1826 25C19.8831 25 25.3652 19.6 25.3652 13C25.3652 6.4 19.8831 1 13.1826 1C6.48218 1 1 6.4 1 13C1 19.6 6.48218 25 13.1826 25Z"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.30957 12.9999H18.0557"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const AdditionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M12.5 24C18.825 24 24 18.825 24 12.5C24 6.175 18.825 1 12.5 1C6.175 1 1 6.175 1 12.5C1 18.825 6.175 24 12.5 24Z"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.8999 12.5H17.0999"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 17.1V7.89996"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const AddIcon = ({ fill }: { fill: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18zm0 16.893c-4.342 0-7.875-3.55-7.875-7.893 0-4.342 3.533-7.875 7.875-7.875 4.342 0 7.875 3.533 7.875 7.875 0 4.342-3.533 7.893-7.875 7.893zm3.938-8.456h-3.376V8.063a.563.563 0 0 0-1.124 0v3.374H8.062a.563.563 0 0 0 0 1.126h3.376v3.374a.563.563 0 0 0 1.124 0v-3.374h3.376a.563.563 0 0 0 0-1.126z"
      stroke={fill}
      fill={fill}
      strokeWidth=".5"
    />
  </svg>
)

export const WalletIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.8 6.2h4.8"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6 7.4h-3.123C18.335 7.4 16.6 9.012 16.6 11s1.736 3.6 3.876 3.6H23.6c.1 0 .15 0 .192-.002.648-.04 1.164-.519 1.206-1.12.002-.038.002-.085.002-.178V8.7c0-.093 0-.14-.002-.178-.044-.601-.558-1.08-1.206-1.12C23.75 7.4 23.7 7.4 23.6 7.4z"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M23.758 7.4c-.094-2.246-.394-3.624-1.364-4.594C20.988 1.4 18.725 1.4 14.2 1.4h-3.6c-4.525 0-6.788 0-8.194 1.406C1 4.212 1 6.475 1 11s0 6.788 1.406 8.194C3.812 20.6 6.075 20.6 10.6 20.6h3.6c4.525 0 6.788 0 8.194-1.406.97-.97 1.272-2.348 1.364-4.594"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M20.19 11h.011"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const StepCheckIcon = ({
  fill = COLORS.GREEN_COLOR,
}: {
  fill?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
      fill={fill}
    />
    <path
      d="M8.75 14.75L12.25 18.25L19.25 10.75"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const StepCompletedIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 27.5c6.875 0 12.5-5.625 12.5-12.5S21.875 2.5 15 2.5 2.5 8.125 2.5 15 8.125 27.5 15 27.5z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m9.688 15 3.537 3.537 7.088-7.075"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ArrowDownBlueIcon = ({
  fill = COLORS.PRIMARY_COLOR,
  className = "",
  size = "16",
}: {
  fill?: string
  className?: string
  size?: string
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.75A6.26 6.26 0 0 0 1.75 8 6.26 6.26 0 0 0 8 14.25 6.26 6.26 0 0 0 14.25 8 6.26 6.26 0 0 0 8 1.75zm2.537 5.794L8.332 9.75A.464.464 0 0 1 8 9.887a.464.464 0 0 1-.331-.137L5.463 7.544a.472.472 0 0 1 0-.663.472.472 0 0 1 .662 0L8 8.756l1.875-1.875a.472.472 0 0 1 .662 0 .472.472 0 0 1 0 .663z"
        fill={fill}
      />
    </svg>
  )
}

export const StepInProgressIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 27.5c6.875 0 12.5-5.625 12.5-12.5S21.875 2.5 15 2.5 2.5 8.125 2.5 15 8.125 27.5 15 27.5z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="3 4"
    />
  </svg>
)

export const DownArrowIcon = ({
  height = "7",
  width = "12",
}: {
  width?: string
  height?: string
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.762 1.689 6.443 6.348a.573.573 0 0 1-.206.118A.776.776 0 0 1 6 6.5a.8.8 0 0 1-.237-.034.562.562 0 0 1-.206-.118L.222 1.688A.616.616 0 0 1 0 1.206c0-.193.08-.36.237-.498A.816.816 0 0 1 .792.5c.21 0 .395.07.554.207L6 4.772 10.654.707a.801.801 0 0 1 .547-.193c.216 0 .404.069.561.207.159.138.238.3.238.484s-.08.346-.238.484z"
        fill="#1F1F1F"
      />
    </svg>
  )
}

export const PlayIcon = ({ fill }: { fill?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.463c-4.707 0-8.537 3.83-8.537 8.537 0 4.707 3.83 8.537 8.537 8.537 4.707 0 8.537-3.83 8.537-8.537 0-4.707-3.83-8.537-8.537-8.537zM10 20C4.486 20 0 15.514 0 10S4.486 0 10 0s10 4.486 10 10-4.486 10-10 10z"
      fill="#0071FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.911 7.89c-.173.976-.176 3.308-.003 4.235.857-.302 2.712-1.522 3.212-2.126-.498-.586-2.33-1.78-3.209-2.11zm-.298 5.77a.975.975 0 0 1-.718-.278c-.157-.155-.576-.57-.578-3.3-.001-2.9.435-3.331.578-3.475.51-.505 1.342-.159 1.696-.01.507.211 4.067 2.035 4.067 3.398 0 1.279-3.204 3.066-4.022 3.407-.32.133-.683.258-1.023.258z"
      fill="#0071FF"
    />
  </svg>
)

export const FaqIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#069trqm54a)"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10 18.333a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667zM10 13.333V10m0-3.333h.008" />
      </g>
      <defs>
        <clipPath id="069trqm54a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const DeleteIcon = ({
  fill = COLORS.RED_COLOR,
  width = "12",
  height = "14",
}: {
  fill?: string
  width?: string
  height?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.20546 17.3334C7.07629 17.3334 5.97546 17.3209 4.88629 17.2984C3.49296 17.2709 2.52879 16.3675 2.37129 14.9409C2.10879 12.5742 1.65962 6.99588 1.65546 6.94004C1.62712 6.59588 1.88379 6.29421 2.22796 6.26671C2.56712 6.25754 2.87379 6.49588 2.90129 6.83921C2.90546 6.89588 3.35379 12.455 3.61379 14.8034C3.70296 15.6142 4.14046 16.0325 4.91212 16.0484C6.99546 16.0925 9.12129 16.095 11.413 16.0534C12.233 16.0375 12.6763 15.6275 12.768 14.7975C13.0263 12.4692 13.4763 6.89588 13.4813 6.83921C13.5088 6.49588 13.813 6.25588 14.1538 6.26671C14.498 6.29504 14.7546 6.59588 14.7271 6.94004C14.7221 6.99671 14.2705 12.5892 14.0105 14.935C13.8488 16.3909 12.8871 17.2767 11.4355 17.3034C10.3246 17.3225 9.25296 17.3334 8.20546 17.3334Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2567 4.82446H1.125C0.78 4.82446 0.5 4.54446 0.5 4.19946C0.5 3.85446 0.78 3.57446 1.125 3.57446H15.2567C15.6017 3.57446 15.8817 3.85446 15.8817 4.19946C15.8817 4.54446 15.6017 4.82446 15.2567 4.82446Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5335 4.82449C11.5852 4.82449 10.7618 4.14866 10.5752 3.21866L10.3727 2.20533C10.3302 2.05116 10.1543 1.91699 9.95432 1.91699H6.42682C6.22682 1.91699 6.05099 2.05116 6.00016 2.24366L5.80599 3.21866C5.62016 4.14866 4.79599 4.82449 3.84766 4.82449C3.50266 4.82449 3.22266 4.54449 3.22266 4.19949C3.22266 3.85449 3.50266 3.57449 3.84766 3.57449C4.20266 3.57449 4.51099 3.32116 4.58099 2.97283L4.78349 1.95949C4.98932 1.18283 5.66182 0.666992 6.42682 0.666992H9.95432C10.7193 0.666992 11.3918 1.18283 11.5893 1.92199L11.801 2.97283C11.8702 3.32116 12.1785 3.57449 12.5335 3.57449C12.8785 3.57449 13.1585 3.85449 13.1585 4.19949C13.1585 4.54449 12.8785 4.82449 12.5335 4.82449Z"
        fill={fill}
      />
    </svg>
  )
}

export const PhoneIcon = ({ fill = "#C10" }: { fill?: string }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.816 6.059a11.782 11.782 0 0 0 5.125 5.125l1.711-1.71c.21-.21.521-.28.794-.187a8.872 8.872 0 0 0 2.776.443.78.78 0 0 1 .778.778v2.714a.78.78 0 0 1-.778.778C5.92 14 0 8.081 0 .778A.78.78 0 0 1 .778 0H3.5a.78.78 0 0 1 .778.778c0 .972.155 1.905.443 2.776a.78.78 0 0 1-.194.794l-1.711 1.71z"
        fill={fill}
      />
    </svg>
  )
}

export const QuestionFilledIcon = ({ fill = "#C10" }: { fill?: string }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14zM4.311 4.366c-.25.376-.373.747-.373 1.112 0 .177.078.343.236.495a.8.8 0 0 0 .578.227c.388 0 .651-.217.79-.652.147-.416.327-.731.54-.946.212-.213.542-.32.992-.32.384 0 .697.105.94.318a.998.998 0 0 1 .364.78.849.849 0 0 1-.119.44c-.08.135-.18.258-.294.366-.184.169-.373.331-.567.489-.297.246-.534.459-.71.638a2.14 2.14 0 0 0-.423.624c-.282 1.09 1.181 1.177 1.519.4.041-.076.103-.158.186-.25a4.31 4.31 0 0 1 .335-.314c.354-.295.702-.597 1.044-.905a2.65 2.65 0 0 0 .502-.639c.145-.263.218-.56.211-.86 0-.417-.123-.801-.37-1.156-.247-.355-.597-.635-1.05-.842-.454-.205-.976-.308-1.568-.308-.638 0-1.195.122-1.673.37-.477.246-.84.558-1.09.933zm1.873 6.195a.875.875 0 1 0 1.75 0 .875.875 0 0 0-1.75 0z"
        fill={fill}
      />
    </svg>
  )
}

export const AlertIcon = ({
  color = "#CC1100",
  size = "22",
}: {
  color?: string
  size?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 15V11M11 7H11.01"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SuccessTickIcon = ({ fill = "#C10" }: { fill?: string }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14z" fill={fill} />
      <path
        d="M9.885 5.748 6.443 8.864a.533.533 0 0 1-.706 0l-1.62-1.467A.427.427 0 0 1 4 7.086a.435.435 0 0 1 .147-.3.536.536 0 0 1 .675-.028L6.09 7.901l3.089-2.796a.536.536 0 0 1 .675.028c.089.08.14.187.146.3a.427.427 0 0 1-.115.311v.004z"
        fill="#fff"
      />
    </svg>
  )
}

export const InfoIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => {
  return (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.064.88c-.813 0-1.607.224-2.282.644a3.894 3.894 0 0 0-1.513 1.714 3.57 3.57 0 0 0-.234 2.206c.159.74.55 1.421 1.124 1.955a4.212 4.212 0 0 0 2.103 1.045 4.396 4.396 0 0 0 2.373-.217A4.058 4.058 0 0 0 8.48 6.821a3.631 3.631 0 0 0 .692-2.122c0-1.013-.433-1.984-1.203-2.7A4.27 4.27 0 0 0 5.064.88zm0 7a3.61 3.61 0 0 1-1.902-.536 3.244 3.244 0 0 1-1.26-1.428 2.975 2.975 0 0 1-.195-1.839 3.12 3.12 0 0 1 .936-1.63 3.51 3.51 0 0 1 1.753-.87 3.663 3.663 0 0 1 1.977.181c.626.24 1.16.649 1.536 1.172a2.99 2.99 0 0 1 .316 2.986 3.178 3.178 0 0 1-.741 1.032 3.45 3.45 0 0 1-1.11.69c-.416.16-.861.242-1.31.242z"
        fill={fill}
      />
      <path
        d="M5.064 3.58c.189 0 .342-.143.342-.319 0-.176-.153-.318-.342-.318-.19 0-.343.142-.343.318 0 .176.153.318.343.318zM5.405 4.058H4.72v2.39h.684v-2.39z"
        fill={fill}
      />
    </svg>
  )
}

export const MailIcon = ({ fill = "#C10" }: { fill?: string }) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.000976562 0V12H15.999V0H-0.000976562ZM7.99902 8L1.99902 2H13.999L7.99902 8Z"
        fill={fill}
      />
    </svg>
  )
}

export const ArrowRightIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="m2.196.44 8.396 4.86a.805.805 0 0 1 .408.705.818.818 0 0 1-.408.706l-8.4 4.852a.792.792 0 0 1-.906-.086.81.81 0 0 1-.24-.888l1.55-4.578v-.01l-1.55-4.588a.819.819 0 0 1 .24-.891.795.795 0 0 1 .91-.083zm-.392.708 1.504 4.448H6.2c.106 0 .208.043.283.118a.407.407 0 0 1-.283.692h-2.89l-1.507 4.446-.003.007 8.4-4.851-8.4-4.865.003.005z"
        fill={fill}
        stroke={fill}
        strokeWidth=".5"
      />
    </svg>
  )
}
