import { CircularProgress } from "@mui/material"
import styles from "./PageLoader.module.scss"

const PageLoader = () => {
  return (
    <div className={styles["page-loader"]}>
      <CircularProgress color="primary" />
    </div>
  )
}

export default PageLoader
