export enum LOGO_SMALL {
  HEIGHT = "48px",
}
export enum LOGO_LARGE {
  HEIGHT = "68px",
}

export enum DASHBOARD_TAB {
  DASHBOARD = 0,
  MY_CAMPAIGNS = 1,
}
export const COPYRIGHT_TEXT = `© ${new Date().getFullYear()} YOUGotaGift.com Ltd. - `

export enum OTP_VERIFICATION_ACTIONS {
  LOGIN = "login",
  PASSWORD_RESET = "password_reset",
}

export const LANGUAGE_OPTIONS = [
  {
    code: "en",
    displayName: "English",
  },
  {
    code: "ar",
    displayName: "العربية",
  },
]

export enum CAMPAIGN_REDEMPTION_TYPE {
  VIA_LINK = "web",
  VIA_YOUGOTAGIFT_APP = "mobile_app",
  BOTH = "both",
}

export enum CAMPAIGN_VALIDATION_TYPE {
  EMAIL = "email",
  PHONE = "phone",
  BOTH = "email_and_phone",
}

export enum CHARACTER_LIMIT {
  EMAIL = 1000,
  SMS_EN = 100,
  SMS_AR = 100,
}

export enum DELIVER_CAMPAIGN_STEPPER {
  UNKNOWN__ = -1,
  EMAIL_SMS = 0,
  RECEIVER_DETAILS = 1,
}

export enum COLORS {
  PRIMARY_COLOR = "#0071FF",
  RED_COLOR = "#E90606",
  GREEN_COLOR = "#008e07",
  BLACK_COLOR = "#1f1f1f",
}

export const CAMPAIGN_LINK = "www.youcampaign.yougotagift.com/redemption/"

export enum RICHTEXT_EDITOR_CONTENT_LIMIT {
  FAQ_EN = 4000,
  FAQ_AR = 4000,
  STATIC_BLOCK_EN = 2000,
  STATIC_BLOCK_AR = 2000,
}
