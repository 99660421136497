import { createAppSlice } from "@redux/createAppSlice"
import type { PayloadAction } from "@reduxjs/toolkit"

interface BulkConfigurationProps {
  budget: string
  available_balance: string
}

interface InitialStateProps {
  bulkPaymentConfiguration: BulkConfigurationProps | null
}

const initialState: InitialStateProps = {
  bulkPaymentConfiguration: null,
}

export const bulkPaymentSlice = createAppSlice({
  name: "bulkPaymentSlice",
  initialState,
  reducers: create => ({
    updateBulkPaymentConfig: create.reducer(
      (state, action: PayloadAction<BulkConfigurationProps | null>) => {
        state.bulkPaymentConfiguration = action.payload
      },
    ),
  }),
  selectors: {
    selectBulkPayConfig: appData => appData.bulkPaymentConfiguration,
  },
})

export const { updateBulkPaymentConfig } = bulkPaymentSlice.actions

export const { selectBulkPayConfig } = bulkPaymentSlice.selectors
