import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { type FCC } from "@interfaces/common.interface"
import Toast from "@features/common/Toast/Toast"
import PAGE_URLS from "@constants/pageUrls"
const LocaleRoute: FCC = () => {
  const { i18n } = useTranslation()
  const { locale } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const localeArray = ["en", "ar"]

  useEffect(() => {
    if (locale && i18n.resolvedLanguage !== locale) {
      if (localeArray?.includes(locale)) {
        i18n.changeLanguage(locale)
      } else {
        const pathName = pathname.replace(`/${PAGE_URLS.BASE_PATH}/`, "")
        const url =
          "/" +
          PAGE_URLS.BASE_PATH +
          "/" +
          i18n.resolvedLanguage +
          "/" +
          pathName

        navigate(`../${url}`, { replace: true })
      }
    }
  }, [locale])
  return (
    <>
      <Toast />
      <Outlet />
    </>
  )
}
export default LocaleRoute
