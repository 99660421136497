const theme = {
  palette: {
    primary: {
      main: "#0071FF",
    },
    secondary: {
      main: "#1f1f1f",
    },
    error: {
      main: "#e90606",
    },
    info: {
      main: "#b800c4",
    },
  },
}

export default theme
