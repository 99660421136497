enum PAGE_URLS {
  BASE_PATH = "corporate",
  LOGIN = "login",
  NOT_FOUND = "not-found",
  SESSION_EXPIRED = "session-expired",
  FORGOT_PASSWORD = "forgot-password",
  RESET_PASSWORD = "reset-password",
  DASHBOARD = "dashboard",
  MY_CAMPAIGNS = "my-campaigns",
  CREATE_CAMPAIGN = "create-campaign",
  DELIVER_CAMPAIGN = "deliver-campaign",
  CAMPAIGN_PAYMENT = "campaign-payment",
  REPORT = "report",
}

export default PAGE_URLS

export enum CREATE_CAMPAIGN {
  CAMPAIGN_DETAILS = "campaign-details",
  CAMPAIGN_SITE_CONFIG = "campaign-site-config",
}

export enum CAMPAIGN_PAYMENT {
  VIA_BULK = "bulk",
  VIA_EMAIL_AND_SMS = "email-sms",
}
