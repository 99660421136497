import PAGE_URLS from "@constants/pageUrls"
import React, { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

const BasePathRoute = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!pathname.includes(`/${PAGE_URLS.BASE_PATH}/`)) {
      const url = PAGE_URLS.BASE_PATH + pathname

      navigate(`../${url}`, { replace: true })
    }
  }, [pathname])

  return <Outlet />
}

export default BasePathRoute
