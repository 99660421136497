import { Typography } from "@mui/material"
import { convertToCurrency } from "@utils/convertToCurrency"
import { useTranslation } from "react-i18next"
import { Tooltip } from "@mui/material"
import type { TFunction } from "i18next"
import { WalletIcon } from "@features/common/SvgIcons"
import { COLORS } from "@constants/common"

function getTooltipText(type: string, t: TFunction) {
  switch (type) {
    case "totalBalance":
      return t("totalBalanceCalculation")
    case "availableBalance":
      return t("availableBalanceCalculation")
    case "allocatedCampaignBudget":
      return t("allocatedBudgetCalculation")
    default:
      return ""
  }
}

const BalanceCard = ({
  src,
  value,
  currencyCode,
  label,
}: {
  src: string
  value: string
  label: string
  currencyCode: string
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const toolTipLabel = getTooltipText(label, t)

  return (
    <Tooltip title={toolTipLabel} placement="bottom">
      <div
        className={`min-width-330 bg-cl-white brdr-secondary display-flex align-items-center px16 py24`}
      >
        <div
          className={`display-flex align-items-center justify-content-center width50 height50 brdr-box bg-cl-aqua-haze ${language === "ar" ? "ml24" : "mr24"}`}
        >
          <WalletIcon fill={COLORS.BLACK_COLOR} />
        </div>

        <div
          className={`${
            language === "ar" ? "" : "gap5"
          } display-flex flex-direction-column gap5`}
        >
          <h6 className={"fs12 cl-mine-shaft opacity7"}>{t(label)}</h6>
          <Typography color={"secondary"} variant="h4">
            {convertToCurrency(value, currencyCode)}
          </Typography>
        </div>
      </div>
    </Tooltip>
  )
}

export default BalanceCard
