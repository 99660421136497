import { createAppSlice } from "@redux/createAppSlice"
import { type PayloadAction } from "@reduxjs/toolkit"

export interface ToastSliceState {
  message: string | undefined
  severity?: "success" | "error" | "info" | undefined
  openToast?: boolean
  neverClose?: boolean
}

const initialState: ToastSliceState = {
  message: "",
  severity: undefined,
  openToast: false,
  neverClose: false,
}

export const toastSlice = createAppSlice({
  name: "toastSlice",
  initialState,
  reducers: create => ({
    setToast: create.reducer(
      (state, action: PayloadAction<ToastSliceState>) => {
        state.message = action.payload.message
        state.severity = action.payload.severity
        state.openToast = true
        state.neverClose = action.payload.neverClose
      },
    ),
    clearToast: create.reducer(state => {
      state.openToast = false
      state.neverClose = false
      // state.message = undefined
      // state.severity = undefined
    }),
  }),
})

export const { setToast, clearToast } = toastSlice.actions
