import { Button } from "@mui/material"
import PlusIcon from "./PlusIcon"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import PAGE_URLS, { CREATE_CAMPAIGN } from "@constants/pageUrls"
import { useAppDispatch } from "@redux/hooks"
import { resetCampaignDetails } from "@features/createNewCampaign/CreateNewCampaignSlice"

const NewCampaignButton = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  return (
    <Button
      sx={{ width: "330px", fontSize: "20px", fontWeight: 600 }}
      variant="contained"
      startIcon={<PlusIcon />}
      onClick={() => {
        dispatch(resetCampaignDetails())
        navigate(
          `/${PAGE_URLS.BASE_PATH}/${language}/${PAGE_URLS.CREATE_CAMPAIGN}/${CREATE_CAMPAIGN.CAMPAIGN_DETAILS}`,
        )
      }}
    >
      {t("createNewCampaign")}
    </Button>
  )
}

export default NewCampaignButton
