import { type ErrorResponseInterface } from "@interfaces/apidata.interface"

export const transformErrorResponse = (
  response:
    | any
    | {
        data: { errors: { message: string; code: string; params: any } }
        status: any
      },
) => {
  if (response.status === 400) {
    const errorResponse: ErrorResponseInterface = {
      status: response.status,
      message: response.data.errors.message,
      code: response.data.errors.code,
      params: response.data.errors.params,
    }
    return errorResponse
  }
  return response
}
