import { useEffect, useState } from "react"

function useSmoothScrollToTop() {
  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
      setHasScrolled(true) // Update the flag after scrolling to top
    }

    // Scroll to top only on the first mount
    if (!hasScrolled) {
      scrollToTop()
    }
  }, [hasScrolled])

  return hasScrolled
}

export default useSmoothScrollToTop
