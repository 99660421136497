import PAGE_URLS from "@constants/pageUrls"
import { useAppDispatch, useAppSelector } from "@redux/hooks"
import {
  clearErrorStatus,
  selectErrorStatus,
} from "@features/userAuth/UserAuthSlice"
import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useSyncLogout from "@utils/customHooks/useSyncLogout"

const ErrorNavigator = () => {
  useSyncLogout()
  const errorStatus = useAppSelector(selectErrorStatus)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    if (
      errorStatus === 404 ||
      errorStatus === "PARSING_ERROR" ||
      errorStatus === 403
    ) {
      // 404 from server
      navigate(`/${PAGE_URLS.BASE_PATH}/${language}/${PAGE_URLS.NOT_FOUND}`)
    }
    if (errorStatus === 401) {
      navigate(
        `/${PAGE_URLS.BASE_PATH}/${language}/${PAGE_URLS.SESSION_EXPIRED}`,
      )
    }
    return () => {
      dispatch(clearErrorStatus())
    }
  }, [navigate, errorStatus, dispatch])

  return <Outlet />
}

export default ErrorNavigator
