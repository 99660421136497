import { type PayloadAction } from "@reduxjs/toolkit"
import { createAppSlice } from "../../../../redux/createAppSlice"

export interface PartialThemeConfigSliceState {
  language: "en" | "ar"
}

const initialState: PartialThemeConfigSliceState = {
  language: "en",
}

export const partialThemeConfigSlice = createAppSlice({
  name: "giftShopConfig",
  initialState,
  reducers: create => ({
    changeSiteLanguage: create.reducer(
      (state, action: PayloadAction<"en" | "ar">) => {
        state.language = action.payload
      },
    ),
  }),
  selectors: {
    selectSiteLanguage: appData => appData.language,
  },
})

export const { changeSiteLanguage } = partialThemeConfigSlice.actions

export const { selectSiteLanguage } = partialThemeConfigSlice.selectors
