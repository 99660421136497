import { clearToast } from "@features/common/Toast/toastSlice"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import InfoIcon from "@mui/icons-material/Info"
import { Alert, Box, Snackbar } from "@mui/material"
import { useAppDispatch, useAppSelector } from "@redux/hooks"
import { useCallback, useEffect } from "react"

const Toast = () => {
  const openToast = useAppSelector(state => state.toastSlice.openToast)
  const message = useAppSelector(state => state.toastSlice.message)
  const severity = useAppSelector(state => state.toastSlice.severity)
  const neverClose = useAppSelector(state => state.toastSlice.neverClose)
  const dispatch = useAppDispatch()

  const closeToast = useCallback(() => {
    dispatch(clearToast())
  }, [])

  useEffect(() => {
    if (openToast && !neverClose) {
      const timer = setTimeout(() => {
        closeToast()
      }, 5000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [closeToast, openToast])

  const getAlertIcons = (severity: string | undefined) => {
    switch (severity) {
      case "info":
        return (
          <InfoIcon sx={{ color: "#00a3ff", width: "30px", height: "30px" }} />
        )
      case "error":
        return <CancelIcon sx={{ width: "30px", height: "30px" }} />
      case "success":
        return <CheckCircleIcon sx={{ width: "30px", height: "30px" }} />
    }
  }
  return (
    <Box sx={{ width: 1000 }}>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={openToast}
        onClose={closeToast}
        message={message}
      >
        <Alert
          onClose={closeToast}
          severity={severity || undefined}
          icon={getAlertIcons(severity)}
          sx={{
            width: "100%",
            color: "#1f1f1f",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            borderRadius: "12px",
            border: "none !important",
            boxShadow: "0 0 20px 20px rgba(107, 114, 128, 0.1) !important",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Toast
