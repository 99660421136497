import AppThemeProvider from "./theme/AppThemeProvider"
import Router from "./routes/Router"
import "./styles/styles.scss"
import TranslationProvider from "./utils/TranslationProvider"
const langFromUrl = window.location.pathname.includes("/ar/") ? "ar" : "en"

const App = () => {
  return (
    <AppThemeProvider>
      <TranslationProvider lang={langFromUrl}>
        <Router />
      </TranslationProvider>
    </AppThemeProvider>
  )
}

export default App
