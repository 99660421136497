import { DeleteIcon } from "@features/common/SvgIcons"
import styles from "./CampaignStatusChip.module.scss"
import { useTranslation } from "react-i18next"

interface PropsInterface {
  theme: "orange" | "blue" | "green" | "red" | "black"
  label: string
}

const CampaignStatusChip = ({ label, theme }: PropsInterface) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  return (
    <div
      className={`${styles["campaign-status-chip"]} ${
        styles[`campaign-status-chip__${language}`]
      } ${styles[`campaign-status-chip--${theme}`]} `}
    >
      {theme === "red" && label === t("delete") && (
        <div className="display-flex">
          <DeleteIcon />
        </div>
      )}
      <h5
        className={`${styles["campaign-status-chip__text"]} ${
          label !== t("delete") &&
          styles[`campaign-status-chip__text__dot--${language}`]
        } ${styles[`campaign-status-chip__text--${theme}`]} `}
      >
        {label}
      </h5>
    </div>
  )
}

export default CampaignStatusChip
