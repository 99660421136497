import PageLoader from "@features/common/pageLoader/PageLoader"
import {
  selectBasicDetails,
  updateCampaignCount,
} from "@features/home/HomeSlice"
import CampaignCard from "@features/myCampaign/CampaignCard/CampaignCard"
import { useMyCampaignListMutation } from "@features/myCampaign/MyCampaignApi"
import BalanceCard from "@features/myCampaign/balanceCard/BalanceCard"
import type { IMyCampaign } from "@features/myCampaign/types/myCampaignApiType"
import { CircularProgress } from "@mui/material"
import { useAppDispatch, useAppSelector } from "@redux/hooks"
import useSmoothScrollToTop from "@utils/customHooks/useSmoothScrollToTop"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import styles from "./MyCampaign.module.scss"
import NewCampaignButton from "@features/home/newCampaignButton/NewCampaignButton"
import { captureException } from "@sentry/react"

function convertToNumber(balance: string) {
  if (balance) {
    const convertedBalance = balance?.split(" ")?.[1]
    return convertedBalance
  }
  return "0"
}

const MyCampaigns = () => {
  useSmoothScrollToTop()
  const [campaignData, setCampaignData] = useState<IMyCampaign["campaigns"]>([])
  const basicDetails = useAppSelector(selectBasicDetails)
  const [next, setNext] = useState("")
  const [total, setTotal] = useState(0)
  const [refetch, setRefetch] = useState(false)
  const dispatch = useAppDispatch()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const [myCampaignApi, { data, isLoading, isSuccess, isError, error }] =
    useMyCampaignListMutation()

  useEffect(() => {
    const getAllCampaigns = async () => {
      let res: any
      try {
        res = await myCampaignApi({
          lng: language,
        })
      } catch (err) {
        captureException(err, {
          tags: { location: "My Campaign Api" },
        })
      }
      if (res?.data?.total_campaigns_count !== 0) {
        setCampaignData([...(res?.data?.campaigns as IMyCampaign["campaigns"])])
        setNext(res?.data?.next)
        setTotal(res?.data?.total_count)
      }
    }
    getAllCampaigns()
  }, [language, myCampaignApi, refetch])

  useEffect(() => {
    if (isSuccess) {
      const activeCount = data?.active_campaigns_count
      const totalCount = data?.total_campaigns_count
      dispatch(
        updateCampaignCount({
          active_campaigns: activeCount,
          total_campaigns: totalCount,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      captureException(error, {
        tags: { location: "My Campaign Api" },
      })
    }
  }, [isError])

  const freeBalance = convertToNumber(data?.free_balance as string)

  const totalBalance = convertToNumber(data?.available_balance as string)

  const reservedBudget = convertToNumber(data?.reserved_budget as string)

  const fetchMoreData = useCallback(async () => {
    let res: any = await myCampaignApi({
      lng: language,
      url: next,
    })
    setNext(res?.data?.next)
    setCampaignData(prev => [
      ...prev,
      ...(res?.data?.campaigns as IMyCampaign["campaigns"]),
    ])
  }, [language, myCampaignApi, next])

  useEffect(() => {
    const root = document.getElementById("scrollableTarget")
    if (
      !isLoading &&
      next &&
      total !== 0 &&
      root!.scrollHeight <= root!.clientHeight
    ) {
      fetchMoreData()
    }
  }, [total, fetchMoreData, isLoading, next])

  if (isLoading && next?.length === 0) {
    return <PageLoader />
  }

  if (data?.total_campaigns_count === 0 && data) {
    return (
      <div className="bg-cl-aqua-haze">
        <BalanceTiles
          currencyCode={basicDetails?.currencyCode}
          totalBalance={totalBalance}
          reservedBudget={reservedBudget}
          freeBalance={freeBalance}
        />
        <div className="width-100 h-100vh bg-cl-aqua-haze overflow-hidden py140">
          <div className="display-flex overflow-hidden justify-content-center flex-direction-column gap41 align-items-center">
            <img src={"/corporate/assets/no-campaign.svg"} alt="no campaign" />
            <div className="text-align-center">
              <h2 className="fs24 fw600 cl-mine-cl-mine-shaft mb20">
                {t("thereAreNoCampaigns")}
              </h2>
              <NewCampaignButton />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id={"scrollableTarget"} className={styles["my-campaigns"]}>
      {data?.campaigns?.length ? (
        <BalanceTiles
          currencyCode={basicDetails?.currencyCode}
          totalBalance={totalBalance}
          reservedBudget={reservedBudget}
          freeBalance={freeBalance}
        />
      ) : (
        <div
          className={
            "display-flex flex-grow w-100 align-items-center justify-content-center py16 px0"
          }
        >
          <CircularProgress color="primary" />
        </div>
      )}

      {campaignData?.length && total ? (
        <>
          <div className="display-flex align-items-center justify-content-between py0 px24">
            <h3 className="fs14 fw600 cl-pale-sky min-width-160 shrink-0 text-uppercase shrink-0">
              {t("createdCampaigns")}
            </h3>
            <div className="height1 flex-grow bg-cl-alto ml10 w-100"></div>
          </div>
          <InfiniteScroll
            dataLength={campaignData?.length}
            next={fetchMoreData}
            scrollableTarget="scrollableTarget"
            hasMore={campaignData?.length !== total}
            loader={
              <div className="display-flex flex-grow w-100 align-items-center justify-content-center py16 px0">
                <CircularProgress color="primary" />
              </div>
            }
          >
            <div className={styles["my-campaigns__cards-container"]}>
              {campaignData?.map(details => {
                return (
                  <CampaignCard
                    key={details?.id}
                    refetchCampaign={() => {
                      setRefetch(prev => !prev)
                    }}
                    details={details}
                  />
                )
              })}
            </div>
          </InfiniteScroll>
        </>
      ) : (
        ""
      )}
    </div>
  )
}

const BalanceTiles = ({
  totalBalance,
  reservedBudget,
  freeBalance,
  currencyCode,
}: {
  totalBalance: string
  reservedBudget: string
  freeBalance: string
  currencyCode: string
}) => {
  return (
    <div className="display-flex gap24 py30 px24">
      <BalanceCard
        currencyCode={currencyCode}
        label={"totalBalance"}
        value={totalBalance}
        src="/corporate/assets/icons/wallet-icon.png"
      />
      <BalanceCard
        currencyCode={currencyCode}
        label={"allocatedCampaignBudget"}
        value={reservedBudget}
        src="/corporate/assets/icons/lock-icon.png"
      />
      <BalanceCard
        currencyCode={currencyCode}
        label={"availableBalance"}
        value={freeBalance}
        src="/corporate/assets/icons/wallet-icon.png"
      />
    </div>
  )
}

export default MyCampaigns
