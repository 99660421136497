export function convertToCurrency(balance: number | string, currency?: string) {
  const numberBalance = Number(balance)

  const decimalPlaces = balance.toString().includes(".")
    ? balance.toString().split(".")[1].length
    : 0

  const uaeDinar = new Intl.NumberFormat("en-US", {
    ...(currency?.length && { style: "currency", currency: currency }),
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  })

  return uaeDinar.format(numberBalance)
}
